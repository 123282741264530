import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
  state () {
    return {
      phoneNumber: '',
      ti: '',
      ts: 123,
      points: 0
    }
  },
  mutations: {
    setPhoneNumber (state, phone) {
      state.phoneNumber = phone
    },
    setTi (state, transcationID) {
      state.ti = transcationID
    },
    setTS (state, ts) {
      state.ts = ts
    },
    setPoints (state, points) {
      state.points = points
    }
  }
})
